import { useEffect } from 'react';

const Fontsize = () => {
  useEffect(() => {
    let docEl = document.documentElement;
    if (window.autoSizeOff === 'off') {
      return;
    }
    let clientWidth = docEl.clientWidth;
    clientWidth = Math.min(750, Math.min(window.innerWidth, document.documentElement.clientWidth));
    if (!clientWidth) {
      return;
    }
    let size = 100 * (clientWidth / 750) + 'px';
    docEl.style.fontSize = size;
  }, []);
  return null;
};
export default Fontsize;
